export const locale = {
  GENERAL: {      
    CANCEL: 'Cancel',      
    CONFIRM: 'Confirm',
    ADD:'Add',
    EDIT:'Edit',
    COPY:'Copy',
    DELETE:'Delete',
    SAVE:'Save',
    SHOW_CONTENT: 'SHOW CONTENT',
    HIDE_CONTENT: 'HIDE CONTENT',
    AUTH:'Not authorized to view the contents of this object',
    DAY:'Days',
    ALL:'All',     
    RELEASE:'Release', 
    RESTART_EDITING:'Restart Edit',
    CHANGE_OWNER:'Change Owner',
    CHANGE_OWNER_2:'Change Agent',
    CHANGE_OWNER_3:'Change Author',
    ADD_DEAL:'Opportunity',
    ADD_Case:'Case',
    CLOSE:'Close',
    PIN:'Pin',
    FIELDHISTORY:'Field History',
    NO_FIELDHISTORY:'No Field History',
    NO_EMAIL:'No Email',
    NO_SMS:'No SMS',
    ACTIVE:'Active',     
    LOGOUT:'Log out', 
    SUCCESS:'SUCCESS',
    DASHBOARD: 'Dashboard',
    DATASHEET:'View',
    NEXT:'Next',
    PRE:'Previous',
    NONE:'None',
    MAIL:'Email',
    LAST_NAME: 'Last name',
    FIRST_NAME: 'First name',
    SHORTCUTS: 'Shortcuts',
    STATUS: 'Status Setting',
    REFRESH: 'Refresh',
    SETTING_BTN:'View&Filter',
    PRODUCT:'Product',
    ACTIVITY:'Activity',
    TO:'TO',
    CC:'CC',
    SENDER:'Sender',
    INCOMING:"Incoming",
    OUTGOING:"Outgoing",
    RELOAD: 'Reload',
    LOGOUT_MSG:"Are you sure you want to log out?",
    SEARCH:"Search",
    UPDATE:"Update",
  },
  //------- Log in
  LOGIN:{
    ACCOUNT:'Username',
    PWD:'Password',
    TENANT:'Tenant',
    TRY:'Get it free',
    LOGIN:'LOG IN',
    LOGIN_2:'Log in UpDay',
    LOGIN_UPCHAT:'Log in UpChat',
    LOGIN_UPGPT:'Log in UpGPT',
    GET_VALI_CODE: 'Get Validation Code',
    LOGIN_VALI_CODE: 'Validation Code',
    DESCRIPTION:'One app to manage everything about your customers.',
    PASSWORD:'Forget password',
    ERROR:'Please enter username and password',
    ERROR_2:'Please enter tenant name',
    ERROR_3:'Incorrect username or password, please try again',
    ERROR_3_VALI_CODE:'Incorrect username, password or validation code, please try again', 
    ERROR_4:'Please enter username to obtain validation code',
    SENT_VALI_CODE:'Validation code has been sent to your email',
    LOGIN_VAL_ERROR:'Unable to send validation code. Please contact system administrator for detail',
  },
  FORGET_PASSWORD:{
    TITLE:'Forget password',
    TENANT:'Tenant',
    MAIL:'Email',
    ERROR: 'The input data is incorrect, please confirm and try again. ',
  },
  TRY_IT_FREE:{
    TITLE:'Get your All-in-one CRM now. Absolutely free.',
    FIRST_NAME:'*First Name',
    LAST_NAME:'*Last Name',
    ORG_NAME:'Company Name',
    ROLE:'Job Title',
    PHONE:'Phone Number',  
    MESSAGE:'Message',
    MESSAGE_2:'Our team is activating the account for you. You will receive an email when it is ready. Stay tuned! ',
    ERROR:'Please enter a valid email', 
  },
  //------- Bower Title
  Bower_Title:{
    UPCHAT:'Upday CIC',
    Oppty_Dashboard: 'Opportunity Dashboard - UpDay',
    Case_Dashboard: 'Case Dashboard - UpDay',
    Activity: 'Activity',
    Oppty_List:'Opportunity List ',
    Oppty:'Opportunity',
    Deal:'Deal',
    Contacts_List:'Contact List',
    Contact:'Contact',
    Company_List:'Company List ',
    Company:'Company',
    Account: 'Account',
    Campaign_List:'Campaign List - UpDay',
    Campaign:'Campaign',
    Segment_List:'Segment List - UpDay',
    Segment:'Segment',
    Case_List:'Case List ',
    Case:'Case',
    KnowledgeArticle_List:'Knowledge Article List - UpDay',
    KnowledgeArticle:'Knowledge Article',
    Setting:'Setting - UpDay',
    Login:'Login UpDay',
    ResetPasswordogin:'Reset Password - UpDay',
    PermissionError:'Permission Error - UpDay',
    PermissionError_PAGE:'Not authorized to view the content of this object',
    PageNotFoundError:'Page Not Found - UpDay',
    PageNotFoundError_PAGE:'This object does not exist, please return to the previous page',
  },
  //---------Rule
  RULE:{
    RULE_R_NUM:'This field is required, please fill in a number',
    RULE_R_NUM_2:'Please fill in 17 digits (can include two decimal places)',
    RULE_R_NUM_3:'Please fill in two digits after the decimal point',
    RULE_R_NUM_4: 'Please fill in 17 digits',
    RULE_R_NUM_5: 'Please fill in positive number',
    RULE_R_NUM_6: 'Please fill in 1-2',
    RULE_NUM:'Please fill in a number',
    RULE_R:'This field is required',
    RULE_R_LASTNAME:'Contact last name is required',
    RULE_R_LASTNAME_2:'Last name is required',
    RULE_R_LASTNAME_3:'User last name is required',
    RULE_R_FIRSTNAME: 'First name is required',
    RULE_R_COMPANY_NAME:'Company name is required',
    RULE_R_OPPTY_NAME:'Opportunity name is required',
    RULE_R_OPPTY_Contact:'Opportunity contact name is required',
    RULE_R_Case_Contact:'Case contact name is required',
    RULE_R_Case:'Case name is required',
    RULE_R_SEGMENT:'Segment name is required',
    RULE_R_CAMPAIGN:'Campaign name is required',
    RULE_R_TEMPLATE_NAME:'Template name is required',
    RULE_R_TITLE:'Title is required',
    RULE_R_ACCOUNT:'User account is required',
    RULE_HTTP:'Please fill in http:// or https:// at the beginning',
    RULE_R_PROCESS:'Process name is required',
    RULE_R_STAGE:'Stage name is required',
    RULE_R_NAME:'Name is required',
    RULE_URL:'Please fill in the correct URL format',
    RULE_EMAIL:'Please fill in the correct Email format',
    RULE_PHONE:'Please fill in the correct phone format',
    RULE_PHOTO:'Please upload image',   
    RULE_CSV:'Please upload CSV file',
    RULE_FILE:'Attachment files cannot exceed 20MB in total',
    RULE_FILE_2:'Photo files cannot exceed 1MB',
    RULE_FILE_3:'CSV files cannot exceed 20MB',
    RULE_FILE_4:'Photo files cannot exceed 20MB',
    RULE_DUE_DATE:'The expected closed date is required',      
    RULE_PROCCESS:'The process date is required',
    RULE_FORECAST:'The forecast category is required',
    RULE_REASON: 'The reason for failure is required',
    RULE_NOTE: 'Note content is required',
    RULE_NOTE_1000: 'Note content cannot exceed 1000 words',
    RULE_DATE: 'Please fill in the correct date format YYYY-MM-DD',
    RULE_DATETIME: 'Please fill in the correct format YYYY-MM-DD HH:mm',
    RULE_5:'Please limit to 5 characters',
    RULE_9:'Please limit to 9 characters',
    RULE_10:'Please limit to 10 characters',
    RULE_16:'Please limit to 16 characters',
    RULE_17:'Please limit to 17 characters',
    RULE_20:'Please limit to 20 characters',
    RULE_30:'Please limit to 30 characters',
    RULE_50:'Please limit to 50 characters',
    RULE_10_50:'Please limit to 10 ~ 50 characters',
    RULE_60:'Please limit to 60 characters',
    RULE_70:'Please limit to 70 characters',
    RULE_86:'Please limit to 86 characters',
    RULE_90:'Please limit to 90 characters',
    RULE_100:'Please limit to 100 characters',
    RULE_200:'Please limit to 200 characters', 
    RULE_250:'Please limit to 250 characters',
    RULE_300:'Please limit to 300 characters',
    RULE_500:'Please limit to 500 characters',
    RULE_1000:'Please limit to 1000 characters',
    RULE_NULL: 'Please fill in',
    RULE_HHMM: 'time format string should be HH:MM',
    RULE_PROGRESS: 'Please fill in 0-100',
    RULE_CF_1: "Options cannot be repeated",
    RULE_CF_2: 'Options cannot contain ","',
  },
  BREADCRUMBS:{
    Contacts:'Contacts',
    Companies:'Companies',
    Deals:'Opportunities',
    Case:'Cases',
    Campaign:'Campaigns',
    Segment:'Segments',
    KnowledgeArticles:'Knowledge Articles',
  },
  //-----DASHBOARD
  DASHBOARD:{    
    TITLE: 'Opportunity Dashboard',  
    EXPECTED_REVENUE:'Expected Revenue',
    SUCCESSFULLY_CLOSED:'Current closed',
    CURRENT_PERIOD:'End of period',
    EMAIL_PERFORMANCE:'Email Performance',
    STAGE_AMOUNT:'Pipeline Amount by Stage',
    SALES_RANKING:'Sales Ranking',
    LATEST_OPPTY: 'Recent Opportunities',
    WITHIN_30_DAYS: 'In 30 days',
    BEST_OPPTY: 'Best Opportunities',
    TOP_5: 'Top 5',
    TODAY: 'Today',
    THIS_YEAR: 'This year',
    THIS_SEASON: 'This quarter',
    THIS_MONTH: 'This month',
    THIS_WEEK: 'This week',
    SELECT_DATE:'Select date',
    //----Headers
    OPPTY_OWNER: 'Opportunity Owner',
    CONTACT_NAME:'Customer Name',
    OPPTY_NAME:'Opportunity Name',
    CURRENT_STAGE: 'Current Stage',
    CREATED_TIME: 'Create Time',
    AMOUNT:'Amount',
    EXPECTED_CLOSE_DATE: 'Expected Closing Date',
    THIS_PERIOD:'This Period',
    PREVIOUS:'Previous',
    DIFFERENCE: 'Difference',
    QUANTITY_SENT: 'Email sent',
    READ: 'Email read',
    CLICKED: 'Email clicked',
    UNKNOWN:'Unknown',
    GO_OPPTY:'View detail'
  },
  //------- CONTACT LSIT
  CONTACT: {
    TITLE: 'Contact',      
    BULK:'Message',
    SEGMENT:'Add Segment',
    EMAIL_PUSH:'Email',
    SMS_PUSH:'SMS',
    MESSAGE_DELETE:'Are you sure you want to delete this contact?', 
    MESSAGE_FIRST:'Please delete', 
    MESSAGE_OPPTY:'related opportunities first', 
    MESSAGE_OPPTY_2:'related opportunities',
    MESSAGE_CASE:'related cases first', 
    MESSAGE_CASE_2:'related cases', 
    MESSAGE_ALL:'Please delete related opportunities or cases first',
    MESSAGE_ERROR:'This contact has an opportunity in progress, please re-assign opportunity owner before deleting.',
  },
  ADDSTATICSEGMENT:{
    TITLE: 'Add Static Segment',    
    NAME:'Segment Name',
  },
  CONTACT_DETAIL: {      
    TITLE: 'Job Title',      
    ORG_NAME:'Company Nmae',
    OWNER:'Contact Owner',
    CONTACT_INFORMATION:'Contact Information',
    FIRST_NAME:'First Name',
    LAST_NAME:'Last Name',
    MOBILE_PHONE:'Mobile Phone',
    OFFICE_PHONE:'Office Phone',
    BIRTHDAY:'Birthday',
    ADDRESS:'Address',
    CITY:'City',
    COUNTRY:'Country',
    LEVEL:'Level',
    ZIP_CODE:'Zip Code',
    DEPARTMENT:'Department',
    PROVINCE:'Province',
    RELATED_CAMPAIGN:'Related Campaign',
    DESCRIPTION:'Description',
    COMPANY_INFORMATION:'Company Information',
    ADD_COMPANY_INFORMATION:'Add Company',
    NO_COMPANY_INFORMATION:'No Company Information',     
    COMPANY_NAME:'Company Name',
    JURISTIC_PERSON_NAME:'Juristic Person Name',
    POSITION:'Position',
    SHAREHOLDING:'Shareholding',
    FAX:'Fax',
    //----NOTE
    ADD_NOTE:'Add Note',
    NO_NOTE:'No Note',
    //----Oppyt
    NO_DEAL:'No Opportunity',
    //----Timeline   
    TIMELINE:'Timeline',
    NO_TIMELINE:'No Timeline',
    TIMELINE_TITLE:'Title has been changed to ',
    TIMELINE_DEPARTMENT:'Department has been changed to ',
    TIMELINE_LEVEL:'Level has been changed to ',
    TIMELINE_OWNER:'Owner has been changed to ',
    //----TAB
    DETAIL:'Detail',
    NOTE:'Note',
    EMAIL:'Email',
    SMS:'SMS',
    UPCHAT:'Chat History',
    ALL_OPPORTUNITIES:'Opportunities',
    ALL_CASES:'Cases',   
    DEAL:'Opportunity Name',
    STAGE:'Current Stage',
    //----MESSAGE
    MESSAGE:'Are you sure you want to delete this note?',
    MESSAGE_2:'Are you sure you want to delete this opportunity?',
    MESSAGE_3:'Are you sure you want to delete this contact?',
    MESSAGE_4:'Are you sure you want to copy this note?',
    MESSAGE_5:"Are you sure you want to delete this contact's company information",
    ERROR:'Please select an image',
    ERROR_2:'Image must be less than 5MB',
    ERROR_3:'Please enter the correct format',
    ERROR_4:'No company name entered',
    ERROR_5:'This company already exists',
    ERROR_6_1:'This contact is a customer of ',
    ERROR_6_2:'please reassign the customer and then delete it',
    ERROR_7:'Opportunities related to this contact are in progress, please reassign before deleting.',
    ERROR_8:'This contact has no company information',
  },
  ADD_CONTACT: {
    TITLE: 'Add Contact',
    FIRST_NAME:'First Name',
    LAST_NAME:'Last Name',
    SELECT_LABEL:'Region',
    MOBILE_PHONE:'Mobile Phone',
    OFFICE_PHONE:'Office Phone',
    LEVEL:'Level',
    ORG_NAME:'Company Name',
    IMAGE:'Change Photo',      
  },
  EDIT_CONTACT: {
    TITLE: 'Edit Contact',
    FIRST_NAME:'First Name',
    LAST_NAME:'Last Name',
    SELECT_LABEL:'Region',
    MOBILE_PHONE:'Mobile Phone',
    OFFICE_PHONE:'Office Phone',
    LEVEL:'Level',
    ORG_NAME:'Company Name',
    IMAGE:'Change Photo',      
  },
  ADDCONTACTORG:{
    TITLE:'Add Company',
    COMPANY_NAME:'Company Name',
    JURISTIC_PERSON_NAME:'Juristic Person Name',
    POSITION:'Position',
    SHAREHOLDING:'Shareholding',
    ERROR: 'This company already exists',
  },
  //------- COMPANY LIST
  COMPANY:{
    TITLE: 'Company', 
    //----MESSAGE
    MESSAGE:'Are you sure you want to delete this company?',
    MESSAGE_2_1:'Please delete ',
    MESSAGE_2_2:' related opportunities first',
    MESSAGE_2_3:'related cases first',
    MESSAGE_3:'Please delete related opportunities or cases first', 
  },
  COMPANY_DETAIL:{
    PHONE:'Phone',
    ADDRESS:'Address',
    WEB:'Website',
    OWNER:'Owner',
    INFO:'Company Information',
    NAME:'Company Name',
    COMPANY_NUMBER:'Tax ID',
    FAX:'Fax',
    TYPE:'Type',
    INDUSTRY:'Industry',
    EMPLOYEE:'Employee',
    ANNUAL_REVENUE:'Annual Revenue',
    REGISTERED_CAPITAL:'Registered Capital',
    ESTABLISH_DATE:'Establish Date',
    RELATED_CAMPAIGN:'Related Campaign',
    DESCRIPTION:'Description',
    CITY:'City',
    COUNTRY:'Country', 
    LEVEL:'Level',     
    ZIP_CODE:'Zip Code',      
    PROVINCE:'Province',
    //----NOTE
    ADD_NOTE:'Add Note',
    NO_NOTE:'No Note',
    //----Oppyt
    NO_DEAL:'No Opportunity',
    STAGE:'Current Stage',
    //----primary contact
    CONTACT:'Primary Contact',      
    NO_CONTACT:'No Primary Contact',
    //----Timeline   
    TIMELINE:'Timeline',
    NO_TIMELINE:'No Timeline',
    TIMELINE_EMPLOYEE:'Employee has been changed to ',
    TIMELINE_REVENUE:'Annual Revenue has been changed to $',
    TIMELINE_CAPITAL:'Registered Capital has been changed to $',
    TIMELINE_OWNER:'Owner has been changed to ',
    TIMELINE_PEOPLE:' people',
    //----TAB
    DETAIL:'Detail',
    NOTE:'Note',      
    ALL_OPPORTUNITIES:'Opportunities',
    ALL_CASES:'Cases',   
    DEAL:'Opportunity Name',      
    //----MESSAGE
    MESSAGE:'Are you sure you want to delete this note?',
    MESSAGE_2:'Are you sure you want to delete this opportunity?',
    MESSAGE_3:'Are you sure you want to delete this company?',      
    ERROR:'Please select an image',
    ERROR_2:'Image must be less than 5MB',      
  },
  ADD_COMPANY: {
    TITLE: 'Add Company',
    ORG_NAME:'Company Name',
    TYPE:'Type',
    INDUSTRY:'Industry',
    IMAGE:'Logo',
    WEB:'Website',
    PHONE:'Phone',
    FAX:'Fax',
    ADDRESS:'Address',
    EMPLOYEE:'Employees',      
  },
  Edit_COMPANY: {
    TITLE: 'Edit Company',
    ORG_NAME:'Company Name',
    TYPE:'Type',
    INDUSTRY:'Industry',
    IMAGE:'Logo',
    WEB:'Website',
    PHONE:'Phone',
    FAX:'Fax',
    ADDRESS:'Address',
    EMPLOYEE:'Employees',      
  },
  ADDMAINCONTACT:{
    TITLE: 'Add Company Contact',
    TITLE_2: 'Edit Company Contact',
    NEW_CONTACT:'Create a new contact',
    OLD_CONTACT:'Select an existing contact',
    CONTACT_NAME: 'Contact Name',
    FIRST_NAME: 'First Name',
    LAST_NAME: 'Last Name',
    JURISTIC_PERSON_NAME:'Juristic Person Name',
    POSITION:'Position',
    SHAREHOLDING:'Shareholding',
    PRIMARY_CONTACT:'Primary Contact',
    ERROR: 'If you need to add new contact which never existed in the System, please click the \'+Add\' button above, if not, plase select contact from the contact list',
  },
  MAINCONTACTS:{
    TITLE: 'Company Contact',
    PRIMARY: 'Primary',
    CONTACT_NAME: 'Contact Name',
    JURISTIC_PERSON_NAME: 'Juristic person name',
    POSITION: 'Position',
    SHAREHOLDING:'Shareholding',
    PHONE:'Phone',
    MAIL:'Email',
    ACTIVE:'Active',
    MESSAGE: 'Are you sure you want to delete this contact? ',
  },
  //------- DEAL LIST
  DEAL:{
    TITLE: 'Opportunity',
    OPPTIES:'Oppties',
    NO_DEAL:'No Opportunity',
    NO_DEAL_STAGE:'No Opportunity Process',
    PROCESS: 'Process',
    //----MESSAGE
    MESSAGE:'Are you sure you want to delete this opportunity?',
    ERROR:'Please establish a business process first',
    DELETE_ERROR:'The following opportunities are closed and cannot be deleted.',
  },
  DEAL_DETAIL:{
    CHANGE_STAGE:'Change Process',
    REOPEN:'Reopen',
    NAME: 'Opportunity Name',
    EXPECTED_CLOSE_DATE:'Expected Closing Date',
    CREATED_ON: 'Created On',
    STATUS: 'Status',
    AMOUNT:'Amount',
    SCORE:'Score',
    PROGRESS:'Progress',
    OWNER:'Owner',
    CLIENT_NAME:'Customer',
    NEW_CLIENT:'Create New Customer',
    CONTACT:'Primary Contact',    
    OPPTY_CONTACT:'Opportunity Contact',
    NO_OPPTY_CONTACT:'No Opportunity Contact',  
    CLIENT_BUDGET:'Budget',
    FORECAST_CATEGORY:'Forecast Category',
    RELATED_CAMPAIGN:'Related Campaign',
    CUSTOMER_NEEDS:'Customer Needs',
    //----Timeline   
    TIMELINE:'Timeline',
    NO_TIMELINE:'No Timeline',
    NO_AMOUNT_CHANGE:'No Amount Change',
    NO_STAGE_CHANGE:'No Stage Change',
    NO_FIELD_CHANGE:'No Field Change',
    NO_ACTIVITY_CHANGE:'No Activity',
    NO_MAIL:'No Email',
    NO_SMS:'No SMS',
    NO_NOTE:'No Note',
    TIMELINE_AMOUNT:'Opportunity amount has been changed to $',
    TIMELINE_NAME:'Opportunity name has been changed to ',
    TIMELINE_FORECAST:'Forecast category has been changed to ',
    TIMELINE_PROCESS:'Opportunity process has been changed to ',
    TIMELINE_OWNER:'Opportunity owner has been changed to ',
    TIMELINE_ECD:'Expected Closed Date has been changed to ',
    SOON_DUE: " will expire in ",
    SOON_DUE_1:' days',
    SOON_DUE_2:"has expired for more than ",
    SOON_DUE_3:" days",
    SOON_DUE_4:"today",
    //----TAB
    DETAIL:'Detail',
    NOTE:'Note',
    EMAIL:'Email',
    SMS:'SMS',
    ALL_CASES:'Cases',  
    //----timeLineType
    TIMELINE_TAB_ALL:'All',
    TIMELINE_TAB_AMOUNT:'Amount Change',
    TIMELINE_TAB_STAGE:'Stage Change',
    TIMELINE_TAB_FIELD:'Field Change',
    TIMELINE_TAB_MAIL:'Email',
    TIMELINE_TAB_SMS:'SMS',
    TIMELINE_TAB_NOTE:'Note',
    //----MESSAGE
    MESSAGE:'Are you sure you want to delete this note?',
    MESSAGE_2:'Are you sure you want to delete this opportunity?',
    MESSAGE_3:'The closed date will be overwritten, do you still want to reopen?',
  },
  ADD_DEAL:{
    TITLE: 'Add Opportunity',
    NAME: 'Opportunity Name',
    ORG:'Company',
    CONTACT:'Contact',
    NO_CONTACT:'Please input Contact name for search',
    NEW_CONTACT:'Create a new contact',
    OLD_CONTACT:'Select an existing contact',
    AMOUNT:'Amount',
    PROCESS:'Select Business Process',
    EXPECTED_CLOSE_DATE:'Expected Closing Date',
    FORCAST:'Forecast Category',
    DEAL_PROCESS:'Business Process',
    ERROR:'An established process must be selected',
    ERROR_2:'You must select an existing contact or click on a new contact',
    ERROR_3:'Contact and company must fill in at least one',
  },
  TO_DEAL:{
    NAME: 'Opportunity Name',
    EXPECTED_CLOSE_DATE:'Expected Closing Date',
    AMOUNT:'Amount',
    SCORE:'Score',
    PROGRESS:'Progress',
    OWNER:'Owner',
    INFO:'Basic Information',   
    GUIDE:'Guide',   
    BUDGET:'Budget Confirmed',
    PROBABILITY:'Probability',
    DESCRIPTION:'Description',
    DETAIL_PAGE:'DETAIL',
    IN:'In',
    LOST:'Lost',
    CLOSED:'Closed',
    WIN:'Won',
    BECAUSE:'Because of'
  },
  RELATEDCONTACTS:{
    TITLE:'Contact',
    NEW_CONTACT:'Create a new contact',
    OLD_CONTACT:'Select an existing contact',
    FIRST_NAME: 'Contact Name',
    LAST_NAME: 'Contact Last Name',
    NO_CONTACT: 'Please input Contact name for search',
    ADD_CONTACT: 'Add Contact',
    CONTACT_NAME: 'Contact Name',
    ORG_NAME: 'Company Name',
    PHONE:'Phone',
    MAIL:'Email',
    ACTIVE:'Active',
    MESSAGE: 'Are you sure you want to delete this contact? ',
    ERROR: 'If you need to add new contact which never existed in the System, please click the \'+Add\' button above, if not, plase select contact from the contact list'
  },
  CHILD_CASE:{
    TITLE:'Child Case',
    SUBJECT: 'Subject',
    CONTACT:'Primary Contact',
    PRIORITY:'Priority',
    STATUS:'Stauts',
    GROUP:'Group',
    TYPE:'Type',
    OWNER:'Agent',
    CLOSED_DATE:'Closed Date',
    ACTIVE:'Active',
    ADD_CHILD_CASE:'Add New Child Case',
    NO_CHILD_CASE: 'Not Found',
    SEARCH_CHILD_CASE: 'Please input case subject for search',
    MESSAGE:'Are you sure you want to delete this child case?',
    ERROR: 'Please select an existing case'
  },
  EDITDEALDATE:{
    TITLE:'Edit Expected Closing Date',
    NAME:'Opportunity Name',
    EXPECTED_CLOSE_DATE:'Expected Closing Date',
  },
  //------- Case
  CASE:{
    TITLE: 'Case',
    CASE:'Cases',
    NO_CASE:'No Case',
    NO_CASE_STAGE:'No Case Process',
    FINISH:'Closed',
    CLOSED:'Closed',
    CASE_NUMBER:'Case Number',
    OWNER:'Agent',
    //----MESSAGE
    MESSAGE:'Are you sure you want to delete this case?',
    ERROR:'Please establish a case process first',
    DELETE_ERROR:'The following cases are closed and cannot be deleted.',
  },
  CASE_DETAIL:{
    CHANGE_STAGE:'Change Process',
    CLOSED:'Closed',
    REOPEN:'Reopen', 
    IN:'in',
    CONTACT:'Primary Contact',
    PHONE:'Incoming Phone',
    EMAIL:'Incoming Email',
    EMAIL_2:'Email',
    EXPECTED_CLOSE_DATE:'Expected Closing Date',
    CREATED_ON:'Created On',
    PRIORITY:'Priority',      
    STATUS:'Status',
    OWNER:'Agent',
    GUIDE:'Agent Guide',      
    DAY:'Days',
    CASE_NAME:'Case Name',
    CASE_NUMBER:'Case Number',
    ORG_NAME:'Company',
    INCOMING_CONTACT:'Incoming Contact',
    SOURCE:'Source',
    GROUP:'Group',
    TYPE:'Type',
    CASE_STATUS:'Status',
    RELATED_CAMPAIGN:'Related Campaign',
    DESCRIPTION:'Description',
    DESCRIPTION_2:'Resolution',
    PARENT_CASE:'Parent Case',
    //----TAB
    DETAIL:'Detail',      
    NOTE:'Note',
    SMS:'SMS',
    PRO:"Products",
    RELATED_ARTICLE:'Related Article',   
    ALL_ARTICLE:'All Article',
    STAGE_CHANGE:'Stage Change',
    STATUS_CHANGE:'Status Change',
    FIELD_CHANGE:'Field History',
    SUBJECT:'Subject',
    CLOSED_DATE:'Closed Date',
    //----Timeline   
    TIMELINE:'Timeline',
    NO_TIMELINE:'No Timeline',      
    NO_STAGE_CHANGE:'No Stage Change',
    NO_STATUS_CHANGE:'No Status Change',
    NO_FIELD_CHANGE:'No Field Change',
    NO_NOTE:'No Note',
    NO_MAIL:'No Email',
    NO_SMS:'No SMS',
    ADD_NOTE:'Add Note',
    ADD_PRO:'Add Product',
    DEL_PRO:'Delete All',
    CASE_CLOSED:'Case Closed',
    TIMELINE_OWNER:'Case owner has been changed to ',
    TIMELINE_NAME:'Case name has been changed to ',
    TIMELINE_CONTACT:'Primary Contact has been changed to ',      
    TIMELINE_STATUS:'Case status has been changed to ',
    TIMELINE_PRIORITY:'Case priority has been changed to ',
    TIMELINE_GROUP:'Case group has been changed to ',      
    TIMELINE_TYPE:'Case type has been changed to ',
    TIMELINE_DUE_DATE:'Expected Close Date has been changed to ',
    TIMELINE_CASE_PROCESS:"Case process has been changed to ",
    //----Right
    PRIMARY_CONTACT_CASE:'Primary Contact Related Cases',
    NO_RELATED_CASE:'No Related Case',
    RELATED_CONTACT:'Case Related Contacts',
    NO_RELATED_CONTACT:'No Related Contact',
    CHILD_CASE:'Child Cases',
    NO_CHILD_CASE:'No Related Child Case',
    KNOWLEDGE_ARTICLE:'Knowledge Article',
    NO_RELATED_ARTICLE:'No Related Article',
    EXPAND:'Expand',
    COLLAPSE:'Collpse',
    REMOVE:'Remove',
    ADD:'Add',
    //----MESSAGE
    MESSAGE:'Are you sure you want to delete this note?',      
    MESSAGE_2:'Are you sure you want to delete this case?',
    MESSAGE_3:'The closed date will be overwritten, do you still want to reopen?',
    MESSAGE_4:'Do you want to close the case with all child cases?',      
    ERROR:'Please enter the correct format',
  },
  ADD_CASE:{
    TITLE: 'Add Case',
    NAME: 'Case Name',
    ORG:'Company',
    CONTACT:'Contact',
    NO_CONTACT:'Please input Contact name for search',
    NEW_CONTACT:'Create a new contact',
    OLD_CONTACT:'Select an existing contact',
    PHONE:'Incoming Phone',
    EMAIL:'Incoming Email',
    SOURCR:'Source',
    GROUP:'Group',
    TYPE:'Type',
    PARENT_CASE:'Parent Case',
    CATEGORY:'Category',
    EXPECTED_CLOSE_DATE:'Expected Closing Date',
    SELECT:'Select A Case Process',
    PROCESS:'Case Process',
    ERROR: 'An established process must be selected',
    ERROR_2: 'Please select an existing contact or click on a new contact',
    ERROR_3: 'Please fill in either Contact or Company',
    ERROR_4: 'Please select an existing case',
    USE_AUTONUMBER: "Automatically fill in serial number",
  },
  TO_CASE:{
    NAME: 'Name',
    EXPECTED_CLOSE_DATE:'Expected Closing Date',
    PRIORITY:'Priority',
    SCORE:'Score',
    STATUS:'Status',
    OWNER:'Agent',
    INFO:'Basic Information',   
    GUIDE:'Agent Guide',         
    DETAIL_PAGE:'DETAIL',
    IN:'In',
    LOST:'Lost',
    CLOSED:'Closed',
    WIN:'Closed',
    SOLVE:'Solve'
   },
   ADD_CASE_PRODUCT:{
    TITLE:'Products List',
   },
   //------- Bulk Message
   Bulk_Message:{
    TITLE: 'Outbound',      
  },
   //------- Segment
   SEGMENT:{
    TITLE: 'Segment',  
    //----MESSAGE
    MESSAGE:'Are you sure you want to delete this segment?',
    MESSAGE_2_1:'Please delete ',
    MESSAGE_2_2:' related campaigns first',
    MESSAGE_3:'Please delete related campaigns first',
    //----head
    NAME:'Segment Name',
    TYPE:'Type',
    PEOPLE:'Contacts',
    CAMPAIGNS:'Campaigns',
    OWNER:'Owner',
    ACTIVE:'Active',
  },
  ACTIVITY:{
    ADD:'Add Activity',
    TITLE:'Activity',
    PRIORITY: 'Priority',
    COMPLETED:'Completed',
    MARK_COMPLETED:'Mark As Completed',
    REGARDING:'Regarding',
    TIME_FRAME:'Time Frame',
    TYPE:'Type',
    OWNER:'Owner',
    SUBJECT:'Subject',
    PROGRESS:'Progress',
    REMARK:'Remarks',
    CONTACT:'Contact',
    ORG:'Organization',
    URGENT:'Urgent',
    IMPORTANT:'Important',
    MEDIUM:'Medium',
    LOW:'Low',
    START_TIME:'Start Date',
    DUE_TIME:'Due Date',
    START_DAY:'Start Day',
    END_DAY:'Due Day',
    COMPLETED_UNCOMPLETED:'Incomplete',
    COMPLETED_COMPLETED:'Completed',
    UNCOMPLETED_ACTIVITY:'Incomplete Activity',
    TYPE_SETTING:'Activity Types',
    TYPE_NAME:'Type Name',
    TYPE_COLOR:'Color',
    TYPE_ICON:'Icon',
    ADD_TYPE:'Add Activity Type',
    ADD_TYPE_BTN:'Add Type',
    MESSAGE_DELETE:'Are you sure you want to delete this activity?',
    MONTH:'Month',
    WEEK:'Week',
    DAY:'Day',
    TODAY:"Today",
    MORE:"{0} More",
    MONDAY:"Monday",
    TUESDAY:"Tuesday",
    WEDNESDAY:"Wednesday",
    THURSDAY:"Thursday",
    FRIDAY:"Friday",
    SATURDAY:"Saturday",
    SUNDAY:"Sunday",
    DELETE_ERROR:'No permission to delete the following activities:',
    NO_CONTACT: "No contact found",
    NO_ORG: "No company found",
    NO_REGARDING: "No case or opportunity found",
    NOT_FOUND_404:"The activity does not exist or has been deleted",
  },
  SEGMENT_DETAIL:{
    CONTACTS:'Contacts',      
    CAMPAIGNS:'Campaigns',
    TYPE:'Type',
    OWNER: 'Owner',
    DESCRIPTION:'Segment Description',
    INFO:'Segment Information',
    NAME:'Name',
    RELATED_CAMPAIGN:'Related Campaign',
    TIME:'Times',
    DELETE_CONTACT:'Delete Contact',
    ADD_CONTACT:'Add Contact',
    CAMPAIGN_Analysis_Chart:'Campaign Analysis Chart',
    NO_DATA:'No Available Data',
    CAMPAIGN_Analysis_Statistics:'Campaign Analysis Statistics',
    NO_CAMPAIGN_DATA:'No Campaign Data', 
    //----TAB
    SETUP:'Basic Information',
    CONTACT:'Contact',
    ANALYSIS:'Analysis',
    C_NAME:'Name',
    C_ORG:'Company Name',
    C_PHONE:'Phone',
    C_MAIL:'Email',
    C_AMOUNT:'Total Amount',
    C_OWNER:'Owner',
    C_ACTIVE:'Active',
    CAM_NAME:'Campaign Name',
    CAM_TYPE:'Type',
    CAM_TIME:'Campaign Times',
    S_V_S:'Success vs Sent',
    O_V_S:'Open vs Success',
    C_V_S:'Click vs Success',
    S_V_O:'Success vs Open',
    S_V_C:'Success vs Click',
    SUCCESS:'Success',
    SENT:'Sent',
    OPEN:'Open',
    CLICK:'Click',
    END_TIME:'Last Sent Time',
    //----MESSAGE
    MESSAGE:'Are you sure you want to delete this segment?',
    MESSAGE_2:'Are you sure you want to delete this contact?',      
    ERROR:'Please delete related campaigns first',     
  },
  ADD_SEGMENT:{
    TITLE: 'Add Segment',
    NAME:'Segment Name',
    TYPE:'Type',
    DESCRIPTION:'Description',      
    MESSAGE: 'The new segment has been established, click Confirm to view.',
  },
  EDIT_SEGMENT:{
    TITLE: 'Edit Segment',
    NAME:'Segment Name',
    TYPE:'Type',
    DESCRIPTION:'Description',
  },
  ADDSEGMENTCONTACT:{
    TITLE: 'Segment Contacts',
    ADD_CONTACT:'Add Contact',
    FIRST_NAME:'Contact Name',
    ORG_NAME:'Company Name',      
    PHONE:'Phone',
    MAIL:'Email',
  },    
  //------- CAMPAIGN
  CAMPAIGN:{
    TITLE: 'Campaign', 
    //----MESSAGE
    MESSAGE:'Are you sure you want to delete this campaign?',
    MESSAGE_2:'Are you sure you want to delete these campaigns?',
    ERROR:'Please create segments first',
    ERROR_2:'This campaign is in use',
    ERROR_3:'The following campaigns are in use',
    //----head
    NAME:'Campaign Name',
    CHANNEL:'Channel',
    SEGMENT:'Segment',
    PEOPLE:'Contacts',
    OWNER:'Owner',
    ACTIVE:'Active',
  },
  CAMPAIGN_DETAIL:{
    STATUS:'Status',
    CHANNEL:'Channel',
    PEOPLE:'Contacts',
    SEGMENT:'Segment',
    OWNER:'Owner',
    DESCRIPTION:'Description',
    NAME:'Name',
    TYPE:'Type',
    START_TIME:'Start Date',
    END_TIME:'End Date',
    TEMPLATE:'Select Template',
    EMAIL_CONTENT:'Email Content：',
    SMS_CONTENT:'SMS Content：',
    PREVIEW:'Preview',
    SENTING:'Sending...',
    NO_O365:'You have not set up your M365 account. To send emails, go to Settings>Email Sync to set up an M365 account. Thanks!',
    INVALID_O365_TOKEN:'Your M365 account setup has not been used for a long time.  Please go to Settings>Email Sync to remove existing account and set up again. Thanks!',
    GO_SETTING:'Setting',
    SUBJECT:'Subject',
    SMS_CONTENT_2:'SMS Content',
    CAMPAIGN_Analysis_Chart:'Campaign Analysis Chart',
    NO_DATA:'No Available Data',
    CAMPAIGN_Analysis_Statistics:'Campaign Analysis Statistics',
    NO_CAMPAIGN_DATA:'No Campaign Data', 
    ATTACHMENT:'Attachment',
    //----TAB
    CAM_NAME:'Campaign Name',      
    END_TIME_2:'Last Sent Time',
    SETUP:'Basic Information',      
    ANALYSIS:'Analysis',
    //----MESSAGE
    MESSAGE:'Are you sure you want to delete this campaign?',
    ERROR:'Please enter email subject',    
    ERROR_2:'Please enter content',
    ERROR_3:'Please add a contact to the segment first.',
  },
  ADD_CAMPAIGN:{
    TITLE: 'Add Campaign',
    CHANNEL:'Channel',
    NAME:'Campaign Name',
    SEGMENT:'Segment',
    DESCRIPTION:'Description',
  },
  EDIT_CAMPAIGN:{
    TITLE: 'Edit Campaign',
    CHANNEL:'Channel',
    NAME:'Campaign Name',
    SEGMENT:'Segment',
    DESCRIPTION:'Description',
  },
  CAMPAIGNCONTACTLIST:{
    TITLE: 'Campaign Record Contacts',
    ADD_SEG: 'Add Segment',
    Success: 'Successfully sent',
    unSuccess: 'Unsuccessful delivery',
    Open: 'Opened',
    unOpen: 'Unopened',
    Link: 'Link Clicked',
    unLink: 'Not Clicked',
    CONTACT_NAME: 'Contact Name',
    ORG_NAME: 'Company Name',
    PHONE:'Phone',
    MAIL: 'Email',
  },
  //------- KnowledgeArticles
  KNOWLEDGE_ARTICLE:{
    TITLE: 'Knowledge Article',
    MESSAGE:'Are you sure you want to delete this article?',   
    //----Headers
    H_TITLE:'Article Title',
    NUMBER:'Article Number',
    STATUS:'Status',
    UPDATED_ON:'Last Edited Time',
    AUTHOR:'Author',
    ACTIVE:'Active',  
  },
  KNOWLEDGE_ARTICLE_DETAIL:{
    NUMBER:'Article Number',
    SUBJECT:'Article Subject',
    STATUS:'Article Status',
    AUTHOR:'Author',
    TITLE:'Title',
    AUTONUMBER:'Number',
    SUBJECT_1:'Subject',
    STATUS_2:'Status',
    KEYWORD:'Keyword',
    DESCRIPTIONL:'Description', 
    AUTONUMBER_1:'Automatically Generated',
    //-----BREADCRUMBS   
    KNOWLEDGEARTICLES:'Knowledge Articles',
    ADD_KNOWLEDGEARTICLES:'Add Knowledge Articles',
    //----TAB
    DETAIL:'Detail',   
    //----MESSAGE
    MESSAGE:'Are you sure you want to delete this article?',
    MESSAGE_2:'Are you sure you want to publish this article?',
    MESSAGE_3:'Are you sure you want to re-edit this article?',
    ERROR:'Please enter the content of the article',    
  },    
  PREFERENCES:{
    PREFERENCE:"Preference",
    ADD_PREFERENCE:"Add",
    TENANT: "Tenant",
    CODE:"Code",
    VALUE:"Value",
    NAME:"Name",
    DESCRIPTION:"Description",
    ACTIVE: "Active",
    MESSAGE: "Are you sure you want to delete this preference?",
    ERROR: "This preference code already exists.",
    ERROR1: "：is not JSON format.",
    RULE_UPPERCASE: "Must be uppercase English or underscore"
  },
  //------- SETTING
  SETTING:{
    TITLE: 'Setting',
    PERSONALSETTING:'Personal Setting',
    PASSWORDANDLOGIN:'Password And Login',
    EMAILSYNC:'Email Sync',
    EMAILTEMPLATE:'Email Template',
    SMSTEMPLATE:'SMS Template',
    MANAGEUSERS:'Manage Users',
    MANAGEGROUPS:'Manage Groups',
    CUSTOMFIELDS:'Custom Fields',
    TABLESETTING:'Custom Views',
    FILTERSETTING:'Custom Filters',
    PIPELINESTAGESETTING:'Business Process Setting',
    CUSTOMERSERVICESETTING:'Customer Service Setting',
    KNOWLEDGEARTICLESUBJECTSETTING:'Knowledge Article Subject Setting',
    AUTONUMBERSETTING:'AutoNumber Setting',
    PRODUCTSETTING:'Product Setting',
    NOTIFICATIONSETTING: "Notice Board Setting",
    PERSONAL: "Personal",
    COMMUNICATION: "Communication",
    COMPANY_SETTING: "Company Setting",
    DATA_VIEW: "Data View",
    CUSTOMER_SERVICE_SETTING: "Customer Service Setting",
    ACTIVITYTYPES: 'Activity Types',
    SMSVENDOR: 'SMS Vendor',
    UPGPTSETTING: 'UpGPT Setting',
    ASSISTANTSETTINGANDUPLOAD: 'AI Assistant Setting',
    GPTTOOLS: 'Tools box',
    UPLOADSTATUS:'Import/Upload Record',
    TREESETTING: 'Tree Select Setting',
    CALENDARSYNC:"Calendar Sync",
    CUSTOMOPPTYCLOSEDREASON: "Custom Opportunity Closed Reason",
  },
  CUSTOMOPPTYCLOSEDREASON:{
    ADD:"Add Reason",
    ADDREASON:"Add Opportunity Closed Reason",
    NAME:"Closed Reason",
    DESCRIPTION:"Reason description",
    SELECTED:"Can Select",
    ACTIVE:"Active",
  },
  TREESETTING:{
    ADD:'Add Option',
    TS:'Tree Select',
    DEL_MSG:'Are you sure you want to delete this option?',
    OPEN_ALL:'Expand All',
    CLOSE_ALL:'Collapse All',
    NO_OPTION:'There must be at least one option.',
    ERROR:'This option name already exists.',
    IS_USING: 'This option is in use and cannot be deleted.',
    NO_DATA:'Please add a new tree option first',
  },
  UPGPT:{
    BASICSETTING: 'Basic Setting',
    QAUPLOAD: 'Q&A Data Upload',
    FOLDERUPLOAD: 'Folder Upload',
    FILEUPLOAD: 'File Upload',
    UPLOADIMG: 'Image Upload',
    CLOUD: 'Cloud Files',
    BASICSETTINGSAVE: 'Save',
    AIASSISTANTNAME: 'AI Assistant Name',
    AIASSISTANTNAME_TIP1: 'To set your AI assistant\'s name',
    AIASSISTANTNAME_TIP2: 'For example: Ann',
    AIASSISTANTROLE: 'AI Assistant Role',
    AIASSISTANTROLE_TIP1: 'To set your AI assistant\'s role',
    AIASSISTANTROLE_TIP2: 'For example: customer service agent for UpDay, a CRM production designed by ART Solutions Limited',
    TOPIC: 'Topic',
    TOPIC_TIP1: 'Give AI assistant a topic to help it rephrase questions better',
    TOPIC_TIP2: 'For example: UpDay CRM',
    TOPICLIMIT: 'Topic Limit',
    TOPICLIMIT_TIP1: 'If topic goes off, AI assistant will redirect conversation back',
    TOPICLIMIT_TIP2: 'For example: technology',
    RESETDATA: 'Reset all Knowledge',
    QAFILEUPLOADMESSAGE: 'Q&A file type should be csv file (UTF-8)',
    FOLDERUPLOADMESSAGE: 'There should be zip file',
    FILEUPLOADMESSAGE: 'There should be txt、pdf、docx or pptx file only',
    DELETEALLDATA: 'Are you sure to reset all knowledge ?',
    WRONGTYPEINZIP: 'You should only put txt、CSV、pdf、docx、pptx、jpg、jpeg & png file in zip file',
    WRONGTYPEINCSV: 'Q&A file should be csv file (UTF-8)',
    WRONGTYPEINFILE: 'Only accept txt、pdf、docx & pptx file for File Upload',
    WRONGTYPEINIMAGEFILE: 'Only accept  JPG、JPEG、PNG for Image Upload',
    PREFERENCE_WRONG: 'Preferences not set correctly, please contact administrator',
    WAIT: 'Please wait until the file upload is complete and try again.',
    AIASSISTANT_KNOWLEDGE_MANAGEMENT: 'Management of AI assistant knowledge',
    FILE_AMOUNT: 'Total knowledge file amount: ',
    UPDATE_TIME: 'The latest time to update: ',
    NOFILE: 'There is not any knowledge file',
    UPCHAT_INBOX_SETTING: 'UpChat inbox setting',
    TIMEOUT_ERROR:'AI Assistant is still organizing data into a knowledge base in background, do you want to check the progress in "Import/Upload Records"?',
    EMBEDDING_WRONG:"Upload failed. Please check that your CSV file is encoded in UTF-8, and whether there are double quotation marks (e.g., 'XXX') in any of the fields in the CSV file header (first row). Please remove them and try uploading again. And also check you files aren't encrypted. Please remove them and try uploading again. If you still cannot upload successfully, please contact UpDay customer support. Thank you!",
    UPLOAD_FAIL:'Upload Failed',
    FILE: 'File',
    DOWNLOAD: 'Download',
    DOWNLOAD_TXT: 'Download txt file',
    FILE_HAS_BEEN_DELETED: 'File has been deleted, please refresh page',
    WEBSITEANALYSIS: 'Website Analysis',
    ADD_URL: 'Add Url',
    EDIT_URL: 'Edit Url',
    URL: 'URL',
    LEVEL: 'Level',
    OVERLIMIT: 'The maximum amount of url is 5',
    ACTION: 'Action',
    DELETE_CHECK: 'Are you sure to delete this url ?',
    START_ANALYSIS: 'Start Analysis',
    DOWNLOAD_ZIP: 'Download zip file',
    BLANK_WEB_INFO: 'You have to analysis first, then you can download the result',
    NO_URL: 'Please fill in at least one URL first',
    FILE_TABLE: 'Linked Knowledge Files',
    NOT_CLEAN: 'Something wrong when resetting, please reset again. If you still cannot reset successfully, please contact UpDay customer support. Thank you!',
    AUTO_DETECT: 'Auto detect',
    REPLY_LANGUAGE: 'Reply Language',
    REPLY_LANGUAGE_TIP: 'AI assistant will reply in language which you choosed',
  },
  ACTIVITYTYPES:{
    ERROR:"This activity type is in use and cannot be deleted.",
    ERROR_2:"Must have at least one activity type.",
    ERROR_3:"Default activity types cannot be deleted.",
  },
  SMSVENDOR:{
    VENDOR:"Vendor",
    USER_NAME:"User Name",
    USER_PWD:"User Password",
    DEFAULT:"Default",
    MITAKE:"Mitake",
    DOMAIN:"SmSend API URL",
    TIP: "Please refer to the API document provided by Mitake",
    TIP_2:"Example: https://smsapi.mitake.com.tw/api/mtk/SmSend?CharsetURL=UTF8",
  },
  PERSONALSETTING:{
    BOARD_CONTACT_SYNC: 'Synchronize the information of directors and supervisors from the Commercial Department of the Ministry of Economic Affairs as the contact person',
    FIRST_NAME:'First Name',
    LAST_NAME:'Last Name',
    LANGUAGE:'Language',
    CURRENCY:'Currency',
    REGION:'Region',
    OPPTY_DEFAULT_STAGE:'Opportunity Default Stage',
    CASE_DEFAULT_STAGE:'Case Default Stage',
    INBOX_NOTIFICATION_SETTINGS:'Inbox Notification Settings',
    ERROR:'Please select an image',
    ERROR_2:'Photos must be less than 5MB',
  },
  PASSWORDANDLOGIN:{
    TITLE: 'Change Password',
    TITLE_2: 'Reset Password',
    MESSAGE:"For the security of your account, please change your password regularly. And increase the password complexity, don't use easy-to-guess strings. Example: birthday, phone number...etc",
    ERROR:'Please confirm that the password is 8-30 characters long and contains uppercase and lowercase English letters, numbers, and special symbols',
    ERROR_2:'The entered password does not match the new password, or the password format is incorrect',
    ERROR_3:'Please enter your current password',
    ERROR_4:'New Password and Confirm Password are required',
    ERROR_5:'Please re-enter the password',
    ERROR_6:'Failed to change password, please try again',
    ERROR_7:'The new password and the confirmation password are different, please re-enter',
    ERROR_8: 'Failed to reset password, please try again. Cause:',
    ERROR_9:'This reset password link has expired',
    OLD_PASSWORD:'Current Password',
    NEW_PASSWORD:'New Password',
    NEW_PASSWORD_2:'Confirm Password',
  },
  EMAILSYNC:{
    TEXT:'Connected Accounts',
    TEXT_2:'Here is your connected M365 accounts',
    NO_CONNECT:'No Connected M365 Account',
    ADD_ACCOUNT:'Add Account',
    MESSAGE:'Are you sure you want to delete this M365 account setting?',
    ERROR:'Delete failed, please try again',
    DIALOG:'Link M365 Account',
    DIALOG_2:'Click add button will redirect you to M365 login page.  Once succeed, you will be redirected back to setting page',
    DIALOG_S:'Successfully Connect M365 Account',
    DIALOG_S2:'You have successfully connect to M365 account.  Please verify you account email address in Email Sync section.  Thanks!',
    START_SYNC:"Start Sync",
    SYNCING: "Synchronizing",
    H1: "Email synchronization processing:",
    H1_1: "All emails are synchronized to UpDay",
    H1_2: "Only the specified emails are synced to UpDay",
    H1_3:"Open",
    H1_4:"Close",
    H2: "When to start synchronization:",
    H2_1: "Today",
    H2_2: "within 1 month",
    H2_3: "Within 3 months",
    H2_4: "Within 6 months",
    H2_5:"Custom",
    H3: "Synchronously sent and sent Email objects:",
    H3_1: "Only UpDay contacts",
    H3_2: "Everyone",
    H4: "Email restriction settings:",
    H5: "Set blacklist Email or domain",
    H6: "Blacklist Email",
    H7: "Blacklist Domains",
    H8:"Acceptable Domains",
    TOO_MUCH_INBOX:"The number of email in inbox has exceeded the maximum of 9999, please select a shorter period and try again.",
    TOO_MUCH_SENTBOX:"The number of email in sentbox has exceeded the maximum of 9999, please select a shorter period and try again.",
  },
  EMAILTEMPLATE:{
    TEMPLATE:'Template',
    ADD_TEMPLATE:'Add Template',
    EDIT_EMAIL_TEMPLATE:'Edit Email Template',
    ADD_EMAIL_TEMPLATE:'Add Email Template',
    NAME:'Template Name',
    SUBJECT:'Subject',
    //Header
    ADD_DATE:'Created Time',
    END_TIME:'Last Edited Time',
    ACTIVE:'Active',
    MESSAGE:'Are you sure you want to delete it?',
    ERROR:'Please fill in the name of the new template!',
  },
  SMSTEMPLATE:{
    TEMPLATE:'Template',
    ADD_TEMPLATE:'Add Template',
    EDIT_SMS_TEMPLATE:'Edit SMS Template',
    ADD_SMS_TEMPLATE:'Add SMS Template',
    NAME:'Template Name',
    //Header
    ADD_DATE:'Created Time',
    END_TIME:'Last Edited Time',
    ACTIVE:'Active',
    MESSAGE:'Are you sure you want to delete it?',
  },
  MANAGEUSERS:{
    SEAT:'Seats Available',
    ACTIVATED:'Activated',
    UNACTIVATED:'Deactivated',
    INVITE_USERS:'Invite Users',
    MANAGER:'Manage',
    STAFF:'Staff',
    IS_SERVICE_AGENT:'Service Agent',
    MAIL:'Email (will be used as login account)',
    FIRST_NAME:'First Name',
    LAST_NAME:'Last Name',
    USER_ROLE:'User Role',
    INVITE:'Invite',
    EDIT_USER:'Edit User',
    //Header
    NAME:'Name',
    ROLE:'Role',
    LAST_TIME:'Last login time',
    ACTIVE:'Active',
    MESSAGE:'Are you sure you want to deactivate this user?',
    MESSAGE_2:'Are you sure you want to restart this user?',
    ERROR:'Sorry, the maximum number of users has been reached! To add new ones, set existing contacts to inactive.',
    ERROR_2:'Sorry, you do not have sufficient permissions!',
    ERROR_3:'Account already exists.',
    ERROR_4: 'There must be at least one admin user',
  },
  MANAGEGROUPS:{
    ADD_GROUP:'Add Group',
    GROUP:'Group',
    NAME:'Name',
    TYPE:'Type',
    MEMBER:'Member',
    //Header
    ACTIVE:'Active',
    ERROR:'Sorry, you do not have sufficient permissions!',
    ERROR_2:'This group name already exists.',
    MESSAGE:'Are you sure you want to delete this group?',
  },
  CUSTOMFIELDS:{
    SELECT_PROCESS:'Select Process',
    SELECT_PAGE:'Select Page',
    ADD_FIELD:'Add Field',
    EDIT_OPTION:'Edit Field',
    OPTION:'Option',
    TREE_OPTION:'Tree Select',
    ADD_CF:'Add CustomField',
    P_SELECT_PAGE:'Please select a using page',
    SELECT_O_PROCESS:'Please select a opportunity process',
    SELECT_C_PROCESS:'Please select a case process',
    SELECT_MESSAGE: 'Select one or more fields for the form.',
    NAME:'Field Name',
    TYPE:'Field Type',
    DESCRIPTIONS: 'Field Description',
    MANDATORY:'Mandatory',
    //Header
    ACTIVE:'Active',
    NO_OPPTY_STAGE:'No Opportunity Process',
    NO_CASE_STAGE:'No Case Process',
    ERROR:'Please keep at least two options',
    ERROR_2:'Please enter the correct content',      
    ERROR_3:'Please enter at least two options',
    ERROR_4:'Please add a opportunity process first',
    ERROR_5:'Please add a case process first',
    ERROR_6:'Please enter new options and press Enter',
    MESSAGE:'Are you sure you want to delete it?',
  },
  TABLESETTING:{
    PAGE:'Select Page',
    PROCESS:'Select Process',
    NEW:'New View',
    COPY: 'Copy Current View',
    DEFAULT:'Default',
    NAME:'Name',
    MESSAGE:"Are you sure you want to delete it?",
    SET:'Setting',
    FIELD:'Field',
    SEQ:'Sequence',
    TITLE:'Edit Current View',
  },
  FILTERSETTING:{
    FILTER:'Filter',
    ADD:"Add Filter",
    EDIT:"Edit Filter",
    COPY: "Copy Filter",
    VISIBILITY:"Visibility",
    VISIBILITY_0:"Private",
    VISIBILITY_1:"Public",
    DEFAULT: 'Default',
    FILTERFIELD:"Select Field",
    PERSONAL_SETTING: 'Personal Setting',
    SYSTEM_SETTING: 'System Setting',
    CONDITION:"Select Condition",
    ADD_CONDITION:"Add Condition",
    DATE:"Select Date",
    CAMPAIGN: 'Select Campaign',
    SEG:'Select Segment',
    PRODUCT:'Select Product',
    PARENT_CASE: 'Select Parent Case',
    TEXT:"Fill In Text",
    NUM:"Fill In Number",
    OPT:"Choose options",
    TRUE:"True",
    FALSE:"False",
    INPUT_TO_SEARCH: 'Please input company name for search',
    INPUT_CONTACT_TO_SEARCH: 'Please input contact name for search',
    INPUT_PRODUCT_TO_SEARCH: 'Please input product name for search',
    INPUT_PARENT_CASE_TO_SEARCH: 'Please input parent case for search',
    ERROR: 'Please at least add one Condition',
    ERROR2: 'Please select a field',
    ERROR3: 'Please confirm the search condition',
    MESSAGE: 'Are you sure you want to delete it?',
    PUBLIC:'Public',
    PRIVATE:'Private',
    CS:'Custom Setings',
    NO_FILTER:'No Filter',
    DELETED_DATA: 'Deleted Data',
  },
  OP_TEXT:{
    CONTAINS:"Contains",
    NOT_CONTAINS:"Not Contains",
    IS_EMPTY:"Is Empty",
    NOT_EMPTY:"Not Empty",
  },
  OP_NUM:{
    EQUAL:"Equal",
    EQUAL_LESS:"Equal Or Less Than",
    EQUAL_LARGER:"Equal Or Larger Than",
    LESS:"Less Than",
    LARGER:"Larger Than",
  },
  OP_DATE:{
    ON:"On",
    ON_OR_AFTER:"On Or After",
    ON_OR_BEFORE:"On Or Before",
    TODAY:"Today",
    YESTERDAY:"Yesterday",
    TOMORROW:"Tomorrow",
    NEXT_7_DAYS:"Next 7 Days",
    LAST_7_DAYS:"Last 7 Days",
    THIS_WEEK:"This Week",
    THIS_MONTH:"This Month",
    THIS_QUARTER:"This Quarter",
    THIS_YEAR:"This Year",
    NEXT_WEEK:"Next Week",
    NEXT_MONTH:"Next Month",
    NEXT_QUARTER:"Next Quarter",
    NEXT_YEAR:"Next Year",
    LAST_WEEK:"Last Week",
    LAST_MONTH:"Last Month",
    LAST_QUARTER:"Last Quarter",
    LAST_YEAR:"Last Year",
  },
  PIPELINESTAGESETTING:{
    ADD:'Add Process',
    WORRY:"Don't worry about your team not following business processes or SOPs! As long as you set the business process here, the system will guide your team towards better results.",
    ERROR:'This process has been used by other opportunities, please replace the process and delete it.',
    ERROR_2:'This process has been used by other cases, please replace the process and delete it.',
    MESSAGE:'Custom View in this process will be delete too, Are you sure you want to delete it?',
    DEFAULT_PROCESS: 'System Default Stage'
  },
  CUSTOMERSERVICESETTING:{
    ADD:'Add Option',
    TYPE:'Case Type',
    GROUP:'Case Group',    
    CATEGORY:'Case Category',      
    ADD_OPTION:'Add Customer Service Options',
    USEING_TYPE:'Please select the using type',
    NAME:'Option Name',
    OPTION_DESCRIPTION:'Option Description',
    //----TAB
    ACTIVE:'Active',
    MESSAGE:'Are you sure you want to delete it?',
    ERROR_1: "This case group is in use",
    ERROR_2: "This case type is in use",
  },
  KNOWLEDGEARTICLESUBJECTSETTING:{
    ADD_SUBJECT:'Add Subject',
    ADD:'Add Knowledge Article Subject',
    NAME:'Subject Name',
    DESCRIPTION:'Subject Description',
    ACTIVE:'Active',
    MESSAGE:'Are you sure you want to delete it?',
  },
  AUTONUMBERSETTING:{
    CASE_SETTING:'Case Setting',
    PERFIX:'Prefix',
    SUFFIX:'Suffix',
    RECODR_TYPE:'Record Type',
    NUMBER_FORMAT:'Number Format',   
    START_NUMBER:'Start Number',
    CURRENT_NUMBER:'Current Number',
    RESET:'Reset Current Number',
    ACTIVATED:'Activated',
    UNACTIVATED:'deactivated',
    KA_SETTING:'Knowledge Article Setting',
    TIME_MODE:'Timestamp Mode',
    NUMBER_MODE:'Number Mode',
    NO_RECORD:'No Record',
    MESSAGE:'The current number is less than the starting number, do you want to reset the current number?',
    RULE:'Please limit to ',
    RULE_1:' characters',
    AUTO:'Autofill Title',
  },
  PRODUCTSETTING:{
    PRODUCT:'Product',
    CATEGORY:'Category',
    SERIES:'Series',
    NAME:'Name',
    PRODUCTCODE:'Product Code',
    PRICR:'Price',
    COST:'Cost',
    DESCRIPTIONL:'Description',
    ADD_PRODUCT:'Add Product',
    EDIT_PRODUCT:'Edit Product',
    ADD_PRODUCT_2:'Add Product Category',
    EDIT_PRODUCT_2:'Edit Product Category',
    ADD_PRODUCT_3:'Add Product Series',
    EDIT_PRODUCT_3:'Edit Product Series',
    MESSAGE:'Are you sure you want to delete it?',
    ERROR: 'This product is in use',
    ERROR_2: 'This category is in use',
    ERROR_3: 'This series is in use',
  },
  NOTIFICATIONSETTING: {
    ADD_NOTICE: "Add",
    NOTICE: "Notice",
    NOTICEBOARD: "Notice Board",
    TITLE: "Title",
    CONTENT: "Content",
    STATUS: "Status",
    ACTIVE: "Active",
    MESSAGE: "Are you sure you want to delete it?",
    MESSAGE2: "Are you sure? It will show again next login.",
    ACTIVATED: "Activated",
    UNACTIVATED: "Unactivated",
    ERROR: "This title is in use",
    TIMER:"Notice cycle time",
    SECONDTIMES:"second/time"
  },
  INBOX_NOTIFICATION_SETTINGS: {
    ALLOW_WEB_PUSH: "Allow Web Push",
    ALLOW_FOLLOW: "Remind me when records I'm following change",
    ALLOW_MENTION: "Remind me when I'm @mentioned",
    ALLOW_REPLY: "Remind me when a post I'm subscribed to replies to",
    ALLOW_EMOJI: "Remind me when a post I subscribe to is liked",
  },
  CALENDAR_SYNC_SETTINGS: {
    SELECT_CALENDAR: "Select calendar",
    SYNC_TYPE: "Select sync type",
    SYNC_AS: "Sync as type",
    PRIVATE_EVENTS: "Private events",
    SELECT_TO_SYNC: "Sync activities type to calendar",
    SYNC_REGARDING:"Sync regarding data",
    PRIVATE_EVENTS_0:"Don't sync",
    PRIVATE_EVENTS_1:"Sync",
    SYNC_REGARDING_0:"Don’t include regarding info",
    SYNC_REGARDING_1:"Include regarding info",
    NO_SELECT_TYPE:"No selected type.",
    STOP_SYNC:"Stop Sync",
    CONNECT_SUCCESS:"You have successfully connect to M365 account.  Please verify you account email address in Calendar Sync section.  Thanks!",
    SYNC_TYPE_ONE_0:"One-Way Sync",
    SYNC_TYPE_ONE_1:"UpDay activities sync to calendar",
    SYNC_TYPE_ONE_2:"Only edits to UpDay activities will sync back to UpDay",
    SYNC_TYPE_TWO_0:"Two-Way Sync",
    SYNC_TYPE_TWO_1:"UpDay activities sync to calendar",
    SYNC_TYPE_TWO_2:"Calendar events sync to UpDay as activities",
  },
  //--------Common
  CHANGEOWNER:{
    TITLE:'Change Agent',
    TITLE_2:'Change Author ',
    TITLE_3:'Change Owner',      
  },
  CASECARD:{
    TITLE:'Case',
    NO_CASE:'No Case',
    CASE_NUMBER:'Case Number',
  },
  PRODUCTTABLE:{
    NO_PRO:'No Product',
    MESSAGE:'Are you sure you want to delete this product?',
  },
  PRODUCTLINEITEM:{
    QUANTITY:'quantity',
    QUANTITY_H:'Quantity',
    QUANTITY_D:'Total Quantity',
    DAYS:"Days",
    TAX:'Tax',
    TAX_H:'Tax',
    UNIT:'unit',
    DISCOUNT:'discount',
    DISCOUNT_H:'Discount',
    AMOUNT:'amount',
    AMOUNT_H:'Amount',
    SUBTOTAL_LIST_PRICE:'subtotal list price',
    TOTAL_DISCOUNT:'total discount',
    SUBTOTAL_AMOUNT:'subtotal amount',
    SALES_TAX:'sales tax',
    TOTAL:'Total',
    TOTAL_PRODUCTS:'total products',
    TAX_INCLUSIVE:'Tax inclusive',
    TAX_EXCLUSIVE:'Tax exclusive',
    NO_TAX:'No tax',
    SYSTEM_COMPUTE:'system compute',
    MANUAL_INPUT:'manual input',
    DISCOUNT_DATA:'discount data',
    TAX_DATA:'tax data',
    OTHER_CHARGES:'Other Charges',
    ENABLE_TRQ:'Enable Time Range Quantity',
    FROM:'From',
    TO:'To',
    FROMTOERROR:'Can not only fill in a single date.',
    OC_DISCOUNT:'total other charges',
    OC:'other charges',
  },
  CASETABLE:{      
    NO_CASE:'No Case', 
    MESSAGE:'Are you sure you want to delete this case?', 
    SUBJECT:'Subject', 
    CONTACT:'Primary Contact',
    PRIORITY:'Priority',
    STATUS:'Status',
    GROUP:'Group',
    TYPE:'Type',
    OWNER:'Agent',
    CLOSED_DATE:'Closed Date',    
  },
  OPPTYCARD:{
    TITLE:'Opportunity',
    NO_OPPTY:'No Opportunity',
    EXPECTED_CLOSE_DATE:'Expected Closing Date',
  },
  CUSTOMFIELD:{
    TITLE:'Custom Field',
    NO_DATA:'No Custom Field',      
  },
  EMAILEDITOR:{
    RECIPIENT:'Recipient',
    COPY:'Copy',
    ATTACHMENT:'Attachment',
    SUBJECT:'Subject',
    SEND:'Send',
    CLEAR:'Clear',
    TEMPLATE:'Email Template',
    NO_O365:'You have not set up your M365 account. To send emails, go to Settings>Email Sync to set up an M365 account. Thanks!',
    INVALID_O365_TOKEN:'Your M365 account setup has not been used for a long time.  Please go to Settings>Email Sync to remove existing account and set up again. Thanks!',
    NO_DATA: 'No Available Data',
    GO_SETTING:'Setting',
    ERROR:'Attachment files cannot exceed 20MB in total',
    ERROR_2:'This email address is not in the correct format',
    ERROR_3:'Please fill in the fields before sending the Email',
  },
  SMSEDITOR:{
    ADD: 'Add',
    RECIPIENT:'Recipient',
    NUMBER:'Mobile Number',
    ENTER:'Enter mobile number',
    SEND:'Send',
    CLEAR:'Clear',
    TEMPLATE:'SMS Template',
    ERROR:'Please fill in the fields before sending the SMS',
    ERROR_2:'Please enter the correct format',
  },
  LOSEREASON:{
    TITLE:'Case Closed',
    TITLE_2:'Select Lose Reason',
    TITLE_3:'Congrations for the great win!',
    CASE_NAME:'Case Name',
    CONTACT_NAME:'Primary Contact Name',
    OPPTY_NAME:'Opportunity Name',
    CUSTOMER_NAME:'Customer Name',
    CLOSE_DATE:'Closed Date',
    CLOSE_REASON:'Closed Reason',
    STATUS:'Status',
    CLOSED:'Closed',
    OPEN:'Open',
    AMOUNT:'Amount',
    LOSE:'Lose Reason',
    WIN:'Won',
  },
  EMAILCAMPAIGNDIALOG:{
    TITLE:'Email Preview',
    SUBJECT:'Subject',
    ATTACHMENT:'Attachments',
    MESSAGE:'The Email is being sent, click OK to go to the event page to check the delivery progress'
  },
  SMSCAMPAIGNDIALOG:{
    TITLE:'SMS Preview',
    SMS_CONTENT:'SMS Content',
    MESSAGE:'The SMS is being sent, click OK to go to the event page to check the delivery progress'
  },
  ADDPROCESSDIALOG:{
    EDIT:'Edit Process',
    ADD_OPPTY:'Add Opportunity Process',
    ADD_CASE:'Add Case Process',
    NAME:'Process Name',
    PAGE:'Using Page',
    STAGE_NAME:'Stage Name',
    PROBABILITY:'Probability',
    FINISH_DAY:'Expected Days',
    GUIDE:'Guide',
    COLOR:'Color',
    DAY:'Days',
    ERROR:'Stage cannot be null',
    ERROR_2:'This step already been used, so it cannot be deleted.',
    MESSAGE:'Are you sure you want to delete the step?',
  },
  ADDNOTE:{
    TITLE:'Add Note',
    TITLE_2:'Edit Note',
    CONTENT:'Please enter content',
  },
  EMAILATTACHMENTSDIALOG:{
    TITLE:'Email attachment',
  },
  EMAILCONTACTSDIALOG:{
    TITLE:'Related Contacts',
  },
  EMAILOPPTIESDIALOG:{
    TITLE:'Related Opportunities',
    NO_OPPTY:'No Opportunity',
    EXPECTED_CLOSE_DATE:'Expected Close Date : ',
  },    
  REDIRECTDIALOG:{
    NEW_CONTACT: 'View new contact',
    NEW_ORG: 'View new company',
    NEW_OPPTY: 'View new opportunitiy',
    NEW_CASE: 'View new case',
  },
  //------- List.js
  LEVELLIST:{0:"Staff",1:"Manager",2:"Director",3:"C-Level",4:"Owner"},
  KASTATUSLIST:{
    PUBLISHED:'Published',
    WAIT_PUBLISH:'Awaiting Release',
    IN_REVIEW:'Under Review',
    AUTHORING:'Authoring',
  },
  CASESTATUSLIST:{
    OPEN:'Open',
    PENDING:'Pending',
    WAIT_CUSTOMER:'Wait customer',
    WAIT_3RD_PARTY:'Wait 3rd party',
    RESOLVED:'Resolved',
    CLOSED:'Closed',
  },
  OPPTYSTATUSLIST:{
    OPEN:'OPEN',
    WON:'WON',
    LOSE:'LOSE',
  },
  CAMPAIGNTYPELIST:{
    ADVERTISEMENT:'Advertisement',
    DIRECT_MARKETING:'Direct Marketing',
    MARKETING_EVENT:'Marketing Event',
    JOINT_MARKETING:'Joint Marketing',
    OTHERS:'Others',      
  },
  CASEPRIORITYLIST:{
    VERY_HIGH:'Very High',
    HIGH:'High',
    MEDIUM:'Medium',
    LOW:'Low',
    VERY_LOW:'Very Low',
  },
  CASESOURCELIST:{
    EMAIL:'Email',
    WEB_FORM:'Web Form',
    WEB_CHAT:'Web Chat',
    PHONE:'Phone',      
    STORE:'Store',
    OUTBOUND:'Outbound',
    OTHERS:'Others',
  },
  CLOSECASEREASONLIST:{
    RESOLVED:'Resolved',
    ALTERNATIVE:'Alternative solution',
    NO_SOLUTION:'No solution',
    NO_RESPONSE:'Customer no response',
    NOT_AN_ISSUE:'Not an issue',
  },
  CLOSEREASONLIST:{
    null: 'Open',
    0:'Reopen',
    1:'Won',
    2:'Lose to competitors',
    3:"Customer do nothing",
  },     
  NUMBERTYPELIST:{
    3: 'Three digits',
    4: 'Four digits',
    5: 'Five digits',
    6: 'Six digits',
    7: 'Seven digits',
    8: 'Eight digits',
    9: 'Nine digits',
    10: "Ten digits",
    11: 'Eleven digits',
    12: 'Twelve digits',
    13: 'Thirteen digits',
    14: 'Fourteen digits',
    15: 'Fifteen digits',
  },
  SEGMENT_TYPELIST:{
    STATIC:'Static',
    DYNAMIC:'Dynamic',
  },    
  STAGEDEFCOLORLIST:{
    0: 'Red',
    1: 'Orange',
    2: 'Yellow',
    3: 'Green',
    4: 'Blue',
    5: 'Purple',
  },
  INDUSTRYLIST:{
    0:'Agriculture, Forestry, Fishing, Animal Husbandry',
    1:'Mining and Quarrying',
    2:'Manufacturing',
    3:'Electricity and Gas Supply',
    4:'Water Supply and Remediation Activities',
    5:'Construction',
    6:'Wholesale and Retail Trade',
    7:'Transport and Storage',
    8:'Accommodation and Food Service Activities',
    9:'Information and Communication',
    10:'Financial and Insurance Activities',
    11:'Real Estate Activities',
    12:'Professional, Scientific and Technical Activities',
    13:'Support Service Activities',
    14:'Public Administration and Defence; Compulsory Social Security',
    15:'Education',
    16:'Human Health and Social Work Activities',
    17:'Arts, Entertainment and Recreation',
    18:'Other Service Activities',
  },
  ORGTYPELIST:{
    0:'Branches of foreign company',
    1:'Offices of foreign company',
    2:'Branches established by domestic company',
    3:'Cooperation',
    4:'Partnership',
    5:'Limited Company',
    6:'Limited Partnership',
    7:'gmbh & co. kg',
    8:'Others',
    9:'Limited Company by Shares',
    10:'Unlimited Company',
    11:'Sole Proprietorship',
  },
  AGENTSTATUSLIST:{
    ONLINE:'Online',
    AWAY:'Away',
    BUSY:'Busy',
    OFFLINE:'Offline',
  },
  CONTACTSFIELDLIST:{
    created_on:'Created on',
    created_by:'Created By',
    updated_on:'Updated On',
    updated_by:'Updated By',
    owner:'Owner',
    orgs:'Company Name',
    full_name:'Contact Name',
    total_amount:'Total amount',
    title:'Title',
    email:'Email',
    mobile_phone:'Mobile Phone',
    office_phone:'Office Phone',
    description:'description',
    department:'Department',
    birthdate:'Birthdate',
    level:'Level',
    fax:'Fax',
    facebook:'Facebook',
    linkedin:'Linkedin',
    instagram_id:'Instagram',
    line_id:'Line',
    billing_address:'Billing Address',
    city:'City',
    country:'Country',
    zip_code:'Zip Code',
    province:'Province',
    primary_campaign_id:'Related Campaign',
    seg:'Segment'
  },
  ORGSFIELDLIST:{
    created_on:'Created on',
    created_by:'Created By',
    updated_on:'Updated On',
    updated_by:'Updated By',
    owner:'Owner',
    name:'Company Name',
    website:'Website',
    company_number:'Tax ID',
    main_phone:'Phone',
    industry:'Industry',
    employee:'Employees',
    establish_date:'Establish Date',
    annual_revenue:'Annual Revenue',
    registered_capital:'Registered Capital',
    fax:'Fax',
    billing_address:'Billing Address',
    city:'City',
    country:'Country',
    zip_code:'Zip Code',
    province:'Province',
    total_amount:'Total amount',
    organization_type:'Type',
    primary_campaign_id:'Related Campaign',
    description:'description',
  },
  OPPTYSFIELDLIST:{
    created_on:'Created on',
    created_by:'Created By',
    updated_on:'Updated On',
    updated_by:'Updated By',
    owner:'Opportunity Owner',
    name:'Opportunity Name',
    amount:'Amount',
    expected_close_date:'Expected Close Date',
    closed_date:'Closed Date',
    closed_reason:'Closed Reason',
    status: 'Status',
    forecast_category:'Forecast Category',
    customer_needs:'Customer Needs',
    customer_budget:'Customer Budget',
    budget_confirmed:'Budget Confirmed',
    primary_campaign_id:'Related Campaign',
    stage_type:'Opportunity Process',
    oppty_contact:'Contact Name',
    current_stage:'Current Stage',
    primary_contact:'Primary Contact',
    description:'description',
    filter_oppty_contact: 'Contact Name(Person)',
    filter_oppty_org: 'Contact Name(Organization)',
    tax_option:'Tax Option',
  },
  CASESFIELDLIST:{
    created_on:'Created on',
    created_by:'Created By',
    updated_on:'Updated On',
    updated_by:'Updated By',
    owner:'Agent',
    subject:'Case Name',
    case_number:'Case Number',
    due_date:'Due_Date',
    incoming_fullname:'Incoming Contact',
    incoming_phone:'Incoming Phone',
    incoming_email:'Incoming Email',
    source:'Source',
    status:'Status',
    closed_date:'Closed Date',
    closed_reason:'Closed Reason',
    priority:'Priority',
    primary_campaign_id:'Related Campaign',
    primary_contact:'Primary Contact',
    case_org:'Primary Company',
    group:'Group',
    type:'Type',
    stage_type:'Case Process',
    current_stage:'Current Stage',
    description:'Description',
    resolution:'Resolution',
    product:'Product',
    parent_case_id: 'Parent Case',
  },
  CASEDASHBOARD: {
    TITLE: 'Case Dashboard',
    UNSOLVED: 'Unsolved',
    OVERDUE: 'Overdue',
    DUE_TODAY: 'Due Today',
    CLOSED: 'Closed',
    OPEN: 'Open',
    ON_HOLD: 'On Hold',
    HIGH_PRIORITY: 'High Priority',
    AVERAGE_RESOLVED_TIME: 'Average Resolved Time',
    DAY: 'Days',
    HOUR: 'Hours',
    MIN: 'Mins',
    PRIORITY: 'Priority',
    SOURCE: 'Source',
    GROUP: 'Group',
    TYPE: 'Type',
    PROCESS: 'Process',
    LINE_CHART: 'Case Line Chart',
    RECEIVED: 'Received Cases',
    RESOLVED: 'Resolved Cases',
    CASE_RANKING: 'Cases Ranking',
    CASES: 'Cases',
    UP: 'Up',
    DOWN: 'Down',
    AGENT_STATUS: 'Agent Status',
    AGENT_TOTAL: 'Total Agent'
  },
  UPCHATDASHBOARD:{
    TITLE: 'UpChat Dashboard',
  },
  CAMPAIGNSTAUSLIST:{
    PROPOSED:'Proposed',
    READY_TO_ACTIVATE:'Ready to activate',
    ACTIVATED:'Activated',
    COMPLETED:'Completed',
    CANCELLED:'Cancelled',
    SHELVED:'Shelved',
    NOT_IN_USE:'Not in use',
  },
  IMPORT_EXPORT:{
    TITLE:'Import CSV',
    TITLE_2:'Export Csv',
    IMPORT:'Import',
    EXPORT:'Export',
    PAGE:'Page',
    DATATYPE:'CSV Type',
    STAGE:'Stage',
    DOWNLOAD:'Download',
    TEMP:'Template',
    DB_DATA:'All Data',
    SELECT:'Select file',
    NOTICE:'Notice',
    DOWNLOAD_TEMP:'Download Template',
    ERROR:'This browser does not support the export function, it is recommended to use Chrome or Edge',
    CONTACT_LABEL:'Notice\n1. If you fill in the ID field, the information will be updated\n2. When filling in the Owner field, please separate the names with spaces, such as: David Smith\n3. Company Name, Campaign field content If it does not exist, it will be created automatically\n4. If the custom field name is duplicated, or if it is the same as the existing field name, the data will be overwritten\n5. When scientific notation appears in the data content, please adjust the cell format in Excel , to avoid data storage errors\n6. When filling in the date, please input in the format yyyy-mm-dd',
    ORG_LABEL:'Notice\n1. If the ID field is filled in, the data will be updated\n2. When filling in the Owner field, please separate the names with spaces, such as: David Smith\n3. When the content of the Campaign field does not exist , it will be created automatically\n4. If the name of the custom field is repeated, or the name of the existing field is the same, the data will be overwritten\n5. When scientific notation appears in the data content, please adjust the cell format in Excel to avoid data Storage error\n6. When filling in the date, please input in the format yyyy-mm-dd',
    OPPTY_LABEL:'Notice\n1. If the ID field is filled in, the information will be updated\n2. When filling in the Owner, Oppty Contact, Primary Contact fields, please separate the names with spaces, such as: David Smith\n3. Oppty If the content of Org, Oppty Contact, Primary Contact, Campaign field does not exist, it will be created automatically\n4. If the name of the custom field is duplicated, or the name of the existing field is the same, the data will be overwritten\n5. The content of the data will appear When using scientific notation, please adjust the cell format in Excel to avoid data storage errors\n6. When filling in the date, please input it in yyyy-mm-dd format\n7. Do not change the Stage Type, and confirm that the Current Stage is filled in correctly' ,
    CASE_LABEL:'Notice\n1. If the ID field is filled in, the information will be updated\n2. When filling in the Owner and Primary Contact fields, please separate the names with spaces, such as: David Smith\n3. Primary Contact, Case If the content of the Org, Campaign, Group, Type fields does not exist, it will be created automatically\n4. If the custom field name is duplicated, or the name of the existing field is the same, the data will be overwritten\n5. The scientific notation appears in the data content Please adjust the cell format in Excel to avoid data storage errors\n6. When filling in the date, please input it in yyyy-mm-dd format\n7. Do not change the Stage Type, and confirm that the Current Stage is filled in correctly\n8. If the Case Number field is not filled in, it will be automatically created according to the autonumber setting',
    KA_LABEL:'Notice\n1. If the ID field is filled in, the information will be updated\n2. When filling in the Owner field, please separate the names with spaces, such as: David Smith\n3. When the subject field does not exist , it will be created automatically\n4. When scientific notation appears in the data content, please adjust the cell format in Excel to avoid data storage errors\n5. Please fill in the Content field in HTML format\n6. If the Article Number field is not filled in, it will be automatically created according to the autonumber setting',
    COPY:'Copy Message',
    ERROR_2:'Please confirm the content of the imported file, or use the template file',
    ERROR_3:'System is still processing data importation in background, Please wait and refresh page to check if data is imported sucessfully.',
    COPY_2:'Copied',
    SEARCH:'Search Words',
    ING:'Processing',
    OPPTY_PRODUCT:'Export Opportunity Products',
    PROGRESS:'Current Progress : ',
    STILL_RUN: 'The import operation is in progress, do you want to check the progress in "Import/Upload Records"?',
  },
  UPCHAT:{
    TITLE:'Message Log',
    SENDER:'Sender : ',
    SENDER_B:'Sender : Robot',
    ROBOT:'B',
    NO_MESSAGE:'No Message Log',
    SHOW:'Show Messages',
    CHANNEL:'Channel',
    BLANK:'Please click conversation to show contact information',
    NO_AGENT:'No agent assigned',
    SYNC:'Are you sure you want to sync this contact?？',
    CIC: 'UpDay CIC',
  },
  DOCUMENT:{
    TITLE:'Documect',
    NO_DOC:'No Documect',
    NAME:'Name',
    DESCRIPTION:"Description",
    CREATE:'Created on',
    UPDATE:'Updated on',
    ADD:'Add Documect',
    EDIT:'Edit Documect',
    DEL:'Delete Documect',
    NEW_FILE:'Select New File',
    MESSAGE_DEL:'Are you sure you want to delete this document?',
    MESSAGE_DEL_2:'Are you sure you want to delete those documents?'
  },
  BULK_EDIT:{
    TITLE:'BulK Edit',
    CHANGE_STAGE:'Change process',
    MESSAGE:'Are you sure you want to update the following cases?',
    MESSAGE_CONTACT:'Are you sure you want to update the following contacts?',
    MESSAGE_OPPTY:'Are you sure you want to update the following opportunities?',
    MESSAGE_ORG:'Are you sure you want to update the following organizations?',
    MESSAGE_DATA:'Are you sure you want to update the ',
    MESSAGE_DATA_2:' selected data?',
    MESSAGE_EMPTY:'Please select the data to update',
    ERROR_CASE:'The following cases have been closed. If you need to edit in batches, please set the "Closed Reason" as "Remove existing value".',
    ERROR_DEAL:'The following business opportunities have been closed. If you need to edit them in batches, please update the "Closed Reason" and choose to reopen the business opportunity.',
  },
  BULK_EDIT_LIST:{
    0:'Keep existing value',
    1:'Replace existing value',
    2:'Remove existing value',
  },
  INBOX:{
    SEND:'Send',
    TITLE:'Inbox',
    UNREAD:'Unread',
    ALL:'All',
    POST:'Post',
    ALERT:'Alert',
    FOCUS:'Focus',
    NWE_POST:'Write a Post',
    SUBSCRIBE:'Subscribe',
    UNSUBSCRIBE:'Unsubscribe',
    READ_MORE:'Read More',
    READ_LESS:'Read Less',
    DELETE: 'Are you sure you want to delete this post?',
    DELETE_ALERT:"Are you sure you want to delete this alert?",
    CONTENT_EMPTY:"Content Blank!",
    CONTENT_NOT_EMPTY:"If you discard now, you'll lose the content. You really want to discard?",
    FILE_LIMIT:"The total file attachments size can't more than 20MB",
    ALL_READ:"Mark All Read",
    ALL_DELETE:"Delete All Post",
    ALL_DELETE_CHECK:"Are you sure you want to delete all post which you are owner?",
    ALL_DELETE_ALERT:"Delete All Alert",
    ALL_DELETE_ALERT_CHECK:"Are you sure you want to delete all alert？",
    ERROR_POST:'This post may be deleted. Please refresh Inbox content.',
    NO_NOTIFICATION:'No new notifications, for now.  Go ahead and take a break!',
    ALL_HAVE_READ:'Congratulations, all notifications have been read!',
  },
  FOLLOW: {
    CONTACT: "Follow or unfollow the selected contacts",
    ORG:"Follow or unfollow selected companies",
    CASE: "Follow or unfollow selected cases",
    OPPTY: "Follow or unfollow the selected opportunities",
    FOLLOW:'Follow',
  },
  UPLOADSTATUS: {
    METHOD: 'Method',
    TYPE: 'Type',
    CREATE_TIME: "Created On",
    UPDATED_TIME: "updated On",
    FILE_NAME: "File Name",
    TOTAL: "Total",
    SUCCESS: "Success",
    FAIL: "Fail",
    STATUS: "Status",
    COMMENT: "Records",
    UPLOAD:'Upload',
    IMPORT:'Import',
    PROCESSING:"Processing",
    COMPLETE:"Complete",
    EMBEDDING:"Analyzing",
    SHOW:'Show Records',
    NO_RECORD:'No Records',
    MESSAGE_DELETE:'Are you sure you want to delete the records?'
  },
  AL_MAIL: {
    TITLE:'AI Writing Assistant',
    GENERATE:"AI Generate",
    SETTING: "AI Generation Settings",
    SEND_ALL: "Send All",
    TONE: "Tone",
    LENGTH: "Length",
    LANGUAGE: "Language",
    TIP: "Tip",
    SIGN: "Signature",
    REMAKE: "Regenerate",
    REMAKEING:"Regenerating...",
    NO_AMIL: "No AI Message",
    SENDING: "Sending...",
    FOLLOWUP: "Follow Up",
    SUMMARY: "Summary",
    CUSTOM: "Custom Type",
    TONE_1: "Professional",
    TONE_2: "Relax",
    TONE_3: "Stimulation",
    TONE_4: "Interesting",
    TONE_5: "Enthusiasm",
    LEN_1: "Short",
    LEN_2: "Medium",
    LEN_3: "Detailed",
    STATUS_1: "No message",
    STATUS_2: "Generated",
    STATUS_3: "Saved",
    STATUS_4:"Unsaved",
    STATUS_5:"Fail",
    CHANGE_ERROR: "Changing the channel or segment will delete the generated messages.",
    UNDO:"Undo",
    REMAKE_ALL: "Are you sure you want to delete existing data and generate messages with current settings?",
    SEND_CHECK: "Are you sure you want to send all messages?<br>Existing messages, settings will be deleted after completion.",
    MAKE_MSG: "The message is being generated, please wait patiently.<br>Click Update to confirm the current progress.",  
    SMS_VENDOR: "Please set the SMS service provider to 'MITAKE'. If this service is not enabled, please contact the UpDay service team.",
    NO_CONTACT:"No contact available yet"
  },
  // GPT----------------------------------------------------------------
  GPT_MENU:{
    DASHBOARD:"Dashboard",
    KNOWLEDGE:"Knowledge",
    CHATBOT:"Chatbot",
    CHAT_HISTORY:"Chat History",
    USER_MANAGEMENT:"User Management",
    USAGE_ANALYSIS:"Usage Analysis",
    UPLOADSTATUS:'Upload Record',
    BACKTO:"Back to ",
    MEETINGRECAP:"Meeting Recap",
    AINOTEBOOK:"AI Notebook",
  },
  GPT_KNOWLEDGE:{
    TITLE:"Knowledges",
    NAME : "Knowledge base name",
    DOCS: "Number of docs",
    CREATED_ON: "Created on",
    UPDATED_ON: "Last updated",
    UPDATED_BY: "Last updated by",
    ROBOTS: "Used by number of robot",
    BACKTO:"Back to ",
    LAST_UPDATED:"Last Updated",
    ONOFF:"Turn On/Off knowledge",
    LINKBOT:"Link Chatbot",
    NO_LINKBOT:"Not yet linked",
    CLEAR:"Clear",
    CLEAR_ALL:"Clear Knowledge",
    DELETE:'Delete Knowledge',
    DEL_MSG:"Are you sure you want to delete this knowledge?",
    CLEAR_MSG:"Are you sure you want to clear this knowledge?",
    SETTING:"Knowledge Setting",
    UPLOAD:"Knowledge File Upload",
    ERROR: "This knowledge name already exists",
    PUBLIC:"Share knowledge base",
    ISPUBLIC:"Shared",
    ADD_COLUMN:"Add Field",
    EDIT_COLUMN:"Edit Field",
    CLEAR_ERROR: "The knowledge files are cleared, please refresh the screen later.",
    DELETE_ERROR: "The knowledge will be deleted after it' files cleared. Please refresh the screen later.",
    SINGAL_DELETE:"Are you sure you want to delete this file?",
    IMAGE_SELECTION: "Image processing options",
    DELETING: "The file is being deleted, please refresh this page later.",
    TIP_1: "The following files will be used to build a knowledge base, and the AI ​​robot will answer questions based on the content of the knowledge base.",
    TIP_2: "Upload the Q&A file here. The file must be in csv format and the content must be UTF-8 encoded.",
    TIP_3: "Upload the compressed file here. The file must be in zip format. Please compress the file directly without putting it in another folder.",
    TIP_4: "Upload txt, pdf, docx, pptx files here. If you need to upload csv files, please go to the Q&A information to upload.",
    TIP_5: "Add websites that need to be analyzed here. You can add up to 5 websites, and the depth is up to 2. After the addition is completed, please click 'Start Analysis'.",
    TIP_6: "Upload images in JPG, JPEG, PNG format here.",
  },
  ADD_KNOWLEDGE:{
    TITLE:'Add knowledge base',
    NAME:'name',
    DESCRIPTION:'Description',
    NAME_HINT:'After the knowledge is established, the name cannot be modified. ',
  },
  GPT_UPLOADIMG:{
    DESCRIBE_IMG: 'Image Understanding',
    CUSTOMER_ATTRIBUTE: 'Custom Attributes',
    IMG_SEARCH: 'Image Search',
    TIP_1: "AI will provide a detailed description of the image, allowing users to ask questions and search for answers based on it.",
    TIP_2: "AI will extract attributes from the image and save them to a table. This can be useful if you want AI to answer questions like 'Top three most expensive items in Asia.' Please contact us to enable this feature.",
    TIP_3: "AI will analyze the image sent by the user and find similar images. This is useful for scenarios like product searches.",
    FILEUPLOADMESSAGE: 'Only JPG, JPEG and PNG files are accepted',
    START_ANALYSIS: 'Start Analysis',
    IMAGE_STATUS: 'Image Status',
  },
  GPT_CLOUD:{
    CONNECT_CLOUD: 'Connect Cloud',
    CONNECT_ACCOUNT: 'Connect Account',
    RESET: 'Reset',
    SAVE: 'Save',
    DELETE_CHECK: 'Are you sure you want to delete this cloud account setting?',
    CLOUDSYNC_ERROR: 'Deletion failed, please try again',
    MY_DRIVE: 'My Drive',
    SHARE_WITH_ME: 'Shared with Me',
    SHAREPOINT: 'Sharepoint',
    EXCEED_MAX_SIZE: 'The total size of uploaded files exceeds',
  },
  GPT_ANALYSIS:{
    NAME: "Data Analysis",
    USED_TOKEN:"Consumed Token",
    MSG_TOKEN: "Average Token consumption per message",
    MSG_Q:"Message Quantity",
    MSG_PER_Q:"Average spent Token",
    ACU: " accumulated results consumed Token",
    THIS_PERIOD:'This period',
    PREVIOUS:'Last period',
    TABLE_HEADER_1:"Intelligent Robot",
    TABLE_HEADER_2:"Use type",
    TABLE_HEADER_3:"Prompt Tokens",
    TABLE_HEADER_4:"Completion Tokens",
    TABLE_HEADER_5:"Fees",
    TABLE_HEADER_6:"Time",
  },
  GPT_AIBOTS:{
    TITLE:"Ai Bots",
    AIASSISTANTNAME: 'AI Assistant Name',
    AIASSISTANTNAME_TIP1: 'To set your AI assistant\'s name',
    AIASSISTANTNAME_TIP2: 'For example: Ann',
    AIASSISTANTROLE: 'AI Assistant Role',
    AIASSISTANTROLE_TIP1: 'To set your AI assistant\'s role',
    AIASSISTANTROLE_TIP2: 'For example: customer service agent for UpDay, a CRM production designed by ART Solutions Limited',
    TOPIC: 'Topic',
    TOPIC_TIP1: 'Give AI assistant a topic to help it rephrase questions better',
    TOPIC_TIP2: 'For example: UpDay CRM',
    TOPICLIMIT: 'Topic Limit',
    TOPICLIMIT_TIP1: 'If topic goes off, AI assistant will redirect conversation back',
    TOPICLIMIT_TIP2: 'For example: technology',
    REPLY_LANGUAGE: 'Reply Language',
    REPLY_LANGUAGE_TIP: 'AI assistant will reply in language which you choosed',
    MODEL:'AI Model',
    MEMORY_LENGTH:'Chat Memory',
    RETURN_KNOWLEDGE:'Get Knowledge',
    INBOX:"Inbox",
    HAS_AGENT_BOT:"This inbox is already connected to another robot.",
    NO_AGENT_BOT: "There is no UpChat robot yet, please contact UpGPT customer service to set it up.",
  },
  ADD_AIBOTS:{
    TITLE:'Add Ai Bot',
    NAME:'Name',
    DESCRIPTION:'Description',
    INBOX:"Inbox",
    KNOWLEDGE:"Knowledge",
    MEMORY_LENGTH:'Chat Memory',
    RETURN_KNOWLEDGE:'Get Knowledge',
    IMAGE_SEARCH: 'Allow image search',
    RETURN_IMAGE_QUANTITY: 'Number of images returned',
    ROLE:"Role",
    SCOPE:"Scope",
    TOPIC:"Topic",
    ONOFF:"Turn On/Off Robot",
    DETAIL:"Robot Detail",
    BRAIN_SIZE:"Brain Size",
    DEL_MSG:"Are you sure you want to delete this bot?",
    C_SETTING:"Character Setting",
    M_1:"Fine tunning the brain setting.",
    M_2:"Robot will reply based on the knowledge base provided",
    M_3:"Remember more chat memory will have a more contextual slower reply.\nGet more knowledge will provide more useful context but slower reply.",
    M_4:"The robot will react and respond to users based on the character setting.",
    M_5:"Role will affect tune and attitude of response.",
    M_6:"Robot only replies to users in the scope provided.",
    M_7:"Help the robot reply better with a context.",
    M_8:"The robot's self-proclaimed name.",
  },
  GPT_UPLOAD:{
    DEL_K:"Deleted Knowledge",
    AutoSync:"Cloud Auto Sync",
    ManualSync:"Cloud Manual Sync",
    AutoKnow: "Linked Knowledge Base"
  },
  GPT_USER:{
    T_1:"Personal Information",
    T_2:"Change Password",
  },
  GPT_CHAT_HISTORY:{
    CHANNEL:"Channel",
    C_USER_NAME:"User Name",
    LAST_CHAT_TIME:"Last Message Time",
    L_SELECT:"Please select a conversation from the left side",
    SEARCH:"Search messages",
  },
  GPT_MEETINGRECAPS:{
    TITLE:"Meeting Recaps",
    NAME:"Name",
    participants:"participants",
    OWNER:"Owner",
    TIME:"Time",
    DELETE:"Are you sure you want to delete this meeting recap?"
  },
  GPT_MEETINGRECAP:{
    TITLE:"Meeting Recap",
    NAME:"Meeting",
    MS:"Meeting Summary",
    OWNER:"Owner",
    MS_TIME:"Meeting Time",
    ANR:"AI Notebook Relationship",
    AN:"AI Notebook",
    OPPTY:"Opportunity",
    ANs:"AI Notes",
    TS:"Transcript",
    ANALYZE: "AI Analyze",
    SUGGEST_TASKS:"Suggested Tasks",
    CREATE_TASKS:"Create Tasks",
    VIEW_TASKS:"View Tasks",
    SUMMARY:"Generate summary",
    VALIDATE: "Please confirm the required fields and bind the opportunity.",
    NO_T:"No Transcript.",
    DELETED_MEETING_RECAP:"This meeting recap have been deleted, please refresh the page.",
    EXIST_SUMMARY_AND_TASK:"Meeting summary and suggest tasks have been generate, please refresh the page and check the content.",
    MAIL_SETTING:"Confirming Mail setting",
    MAIL_GENERATE:"AI mail is being generated",
    EMPTY_SUMMARY:"AI can't generate email with empty meeting summary.",
    EMPTY_CUSTOM_PROMPT:"AI can't generate custom mail with empty prompt.",
    EMPTY_SUMMARY_TASK:"AI can't generate tasks meeting summary.",
    TASK_CANT_FIND_ACTIVITY:"Related activity has been deleted, please create a new one.",
  },
  GPT_AINOTEBOOK:{
    TITLE:"AINotebook Recaps",
    LAST_UPDATED:"Last Updated",
    DESCRIPTION:'Description',
    RECORD_TYPE:'Record Type',
    DELETE:"Are you sure you want to delete this AI Notenbook?",
    DELETE_ERROR:"At least one AI notebook is required",
    DEFAULT:"System Default",
    CRM_TIP:"Select the CRM data that you want Ann to extract from meeting for you automatically.",
    AI_NOTE_TIP:"Define the data that you want Ann to extract from meeting for you automatically. ",
  },
  ADD_AINOTEBOOK:{
    TITLE:'Add AINotebook',
    EDIT:'Edit AINotebook',
    ADD:'Add',
    COLUMN: 'Column',
    NAME:'Name',
    DESCRIPTION:'Description',
    DELETE_ERROR:"Are you sure you want to delete this field?",
    NAME_HINT:'After the AINotebook is established, the name cannot be modified.',
    COLUMN_NAME_HINT:'After the column name is established, the name cannot be modified.',
    LIMIT_HINT:'Field description is limited to 50 words',
    LIMIT_COLUMNS:'The number of columns has reached the limit.',
    CRM_SYSTEM_FIELD: 'CRM Field',
  },
  //Up Meeting
  UPMEETING:{
    LOGIN:"UpSide AI Assistant",
    ANN:"Ann",
    CHANGE_AN: "Changing AI Notebook will clear the recorded information.",
    NOTE_C: "Fields have been filled in.",
    MEETING_OVER:"Your meeting has ended. Click the link below to view the record:",
    L_D_TITLE:"Do you want to keep the last meeting you attended?",
    L_D_LAST:"Keep the meeting",
    L_D_NEW:"Start a new meeting",
    FINISH:"Finish",
    CAPTION_ERROR: "Please open Teams or Google Meetings first.",
    IMAGE_PREFIX: "AI analyzing questions...",
    QUERY_PREFIX: "AI keywords are being generated...",
    SEARCH_PREFIX:"AI information analysis...",
    IMAGE_ERROR: "Image analysis failed, please try again.",
    BACKEND_ERROR: "The system settings are incorrect, please contact the system administrator.",
    SHOT_BTN_TITLE:"AI Information Analysis",
    NO_QA: "Please click the AI icon or enter a keyword to query for answer.",
    Problem_Analysis:"Problem Analysis",
    Causes_Analysis:"Causes Analysis",
    Solutions:"Solutions",
    Actions:"Actions",
    References:"References",
    OutlookError: "There is no open email, or the settings do not match",
  },
  //Other
  BUSINESS_TYPE:{
    OPPTY:"Opportunity",
    CASE:"Case",
    COMPANY:"Company",
    CONTACT:"Contact",
    AI_NOTEBOOK:"AINotebook",
  }
}
